<template>
  <div class="callDataCountContainerFlexColumn">
    <div class="title">今日数据概览</div>
    <el-divider />
    <div class="callDataCountContainerFlexColumn__top">
      <el-row type="flex" justify="center" align="middle">
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">总话务量</div>
            <div class="data">{{ count.answer_total_today }}</div>
            <div class="">
              与昨日对比{{ count.answer_total_yesterday }}
              <span v-if="count.answer_total_today > count.answer_total_yesterday" style="color: red">↑{{ count.answer_total_today - count.answer_total_yesterday }}</span>
              <span v-else-if="count.answer_total_today < count.answer_total_yesterday" style="color: green">↓{{ count.answer_total_yesterday - count.answer_total_today }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">总呼入量</div>
            <div class="data">{{ count.answer_in_total_today }}</div>
            <div class="">
              与昨日对比{{ count.answer_in_total_yesterday }}
              <span v-if="count.answer_in_total_today > count.answer_in_total_yesterday" style="color: red">↑{{ count.answer_in_total_today - count.answer_in_total_yesterday }}</span>
              <span v-else-if="count.answer_in_total_today < count.answer_in_total_yesterday" style="color: green">↓{{ count.answer_in_total_yesterday - count.answer_in_total_today }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">总呼出量</div>
            <div class="data">{{ count.answer_out_total_today }}</div>
            <div class="">
              与昨日对比{{ count.answer_out_total_yesterday }}
              <span v-if="count.answer_out_total_today > count.answer_out_total_yesterday" style="color: red">↑{{ count.answer_out_total_today - count.answer_out_total_yesterday }}</span>
              <span v-else-if="count.answer_out_total_today < count.answer_out_total_yesterday" style="color: green">↓{{ count.answer_out_total_yesterday - count.answer_out_total_today }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">队列中用户放弃数</div>
            <div class="data">{{ count.answer_queue_lost_count_today }}</div>
            <div class="">
              与昨日对比{{ count.answer_queue_lost_count_yesterday }}
              <span v-if="count.answer_queue_lost_count_today > count.answer_queue_lost_count_yesterday" style="color: red">↑{{ count.answer_queue_lost_count_today - count.answer_queue_lost_count_yesterday }}</span>
              <span v-else-if="count.answer_queue_lost_count_today < count.answer_queue_lost_count_yesterday" style="color: green">↓{{ count.answer_queue_lost_count_yesterday - count.answer_queue_lost_count_today }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">平均通话时间</div>
            <div class="data">{{ Math.round(count.answer_seconds_time_today * 100) / 100 }}</div>
            <div class="">
              与昨日对比{{ Math.round(count.answer_seconds_time_yesterday * 100) / 100 }}
              <span v-if="count.answer_seconds_time_today > count.answer_seconds_time_yesterday" style="color: red">↑{{ Math.round((count.answer_seconds_time_today - count.answer_seconds_time_yesterday) * 100) / 100 }}</span>
              <span v-else-if="count.answer_seconds_time_today < count.answer_seconds_time_yesterday" style="color: green">↓{{ Math.round((count.answer_seconds_time_yesterday - count.answer_seconds_time_today) * 100) / 100 }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">平均通话时间率</div>
            <div class="data">{{ (count.answer_seconds_time_percent_today * 100) | getPercent }}</div>
            <div class="">
              与昨日对比{{ (count.answer_seconds_time_percent_yesterday * 100) | getPercent }}
              <span v-if="count.answer_seconds_time_percent_today > count.answer_seconds_time_percent_yesterday" style="color: red">↑{{ ((count.answer_seconds_time_percent_today - count.answer_seconds_time_percent_yesterday) * 100) | getPercent }}</span>
              <span v-else-if="count.answer_seconds_time_percent_today < count.answer_seconds_time_percent_yesterday" style="color: green">↓{{ ((count.answer_seconds_time_percent_yesterday - count.answer_seconds_time_percent_today) * 100) | getPercent }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" align="middle">
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">呼入总接听数</div>
            <div class="data">{{ count.answer_in_count_today }}</div>
            <div class="">
              与昨日对比{{ count.answer_in_count_yesterday }}
              <span v-if="count.answer_in_count_today > count.answer_in_count_yesterday" style="color: red">↑{{ count.answer_in_count_today - count.answer_in_count_yesterday }}</span>
              <span v-else-if="count.answer_in_count_today < count.answer_in_count_yesterday" style="color: green">↓{{ count.answer_in_count_yesterday - count.answer_in_count_today }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">呼入总接听率</div>
            <div class="data">{{ count.answer_in_percent_today | getPercent }}</div>
            <div class="">
              与昨日对比{{ count.answer_in_percent_yesterday | getPercent }}
              <span v-if="count.answer_in_percent_today > count.answer_in_percent_yesterday" style="color: red">↑{{ (count.answer_in_percent_today - count.answer_in_percent_yesterday) | getPercent }}</span>
              <span v-else-if="count.answer_in_percent_today < count.answer_in_percent_yesterday" style="color: green">↓{{ (count.answer_in_percent_yesterday - count.answer_in_percent_today) | getPercent }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">15s内总接听数</div>
            <div class="data">{{ count.answer_15_count_today }}</div>
            <div class="">
              与昨日对比{{ count.answer_15_count_yesterday }}
              <span v-if="count.answer_15_count_today > count.answer_15_count_yesterday" style="color: red">↑{{ count.answer_15_count_today - count.answer_15_count_yesterday }}</span>
              <span v-else-if="count.answer_15_count_today < count.answer_15_count_yesterday" style="color: green">↓{{ count.answer_15_count_yesterday - count.answer_15_count_today }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">15s内总接听率</div>
            <div class="data">{{ count.answer_15_percent_today | getPercent }}</div>
            <div class="">
              与昨日对比{{ count.answer_15_percent_yesterday | getPercent }}
              <span v-if="count.answer_15_percent_today > count.answer_15_percent_yesterday" style="color: red">↑{{ (count.answer_15_percent_today - count.answer_15_percent_yesterday) | getPercent }}</span>
              <span v-else-if="count.answer_15_percent_today < count.answer_15_percent_yesterday" style="color: green">↓{{ (count.answer_15_percent_yesterday - count.answer_15_percent_today) | getPercent }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">呼入未接听数</div>
            <div class="data">{{ count.answer_in_lost_count_today }}</div>
            <div class="">
              与昨日对比{{ count.answer_in_lost_count_yesterday }}
              <span v-if="count.answer_in_lost_count_today > count.answer_in_lost_count_yesterday" style="color: red">↑{{ count.answer_in_lost_count_today - count.answer_in_lost_count_yesterday }}</span>
              <span v-else-if="count.answer_in_lost_count_today < count.answer_in_lost_count_yesterday" style="color: green">↓{{ count.answer_in_lost_count_yesterday - count.answer_in_lost_count_today }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">呼入未接听率</div>
            <div class="data">{{ count.answer_in_lost_percent_today | getPercent }}</div>
            <div class="">
              与昨日对比{{ count.answer_in_lost_percent_yesterday | getPercent }}
              <span v-if="count.answer_in_lost_percent_today > count.answer_in_lost_percent_yesterday" style="color: red">↑{{ (count.answer_in_lost_percent_today - count.answer_in_lost_percent_yesterday) | getPercent }}</span>
              <span v-else-if="count.answer_in_lost_percent_today < count.answer_in_lost_percent_yesterday" style="color: green">↓{{ (count.answer_in_lost_percent_yesterday - count.answer_in_lost_percent_today) | getPercent }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" align="middle">
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">呼出总接听数</div>
            <div class="data">{{ count.answer_out_count_today }}</div>
            <div class="">
              与昨日对比{{ count.answer_out_count_yesterday }}
              <span v-if="count.answer_out_count_today > count.answer_out_count_yesterday" style="color: red">↑{{ count.answer_out_count_today - count.answer_out_count_yesterday }}</span>
              <span v-else-if="count.answer_out_count_today < count.answer_out_count_yesterday" style="color: green">↓{{ count.answer_out_count_yesterday - count.answer_out_count_today }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">呼出总接听率</div>
            <div class="data">{{ count.answer_out_percent_today | getPercent }}</div>
            <div class="">
              与昨日对比{{ count.answer_out_percent_yesterday | getPercent }}
              <span v-if="count.answer_out_percent_today > count.answer_out_percent_yesterday" style="color: red">↑{{ (count.answer_out_percent_today - count.answer_out_percent_yesterday) | getPercent }}</span>
              <span v-else-if="count.answer_out_percent_today < count.answer_out_percent_yesterday" style="color: green">↓{{ (count.answer_out_percent_yesterday - count.answer_out_percent_today) | getPercent }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">呼出未接听数</div>
            <div class="data">{{ count.answer_out_lost_count_today }}</div>
            <div class="">
              与昨日对比{{ count.answer_out_lost_count_yesterday }}
              <span v-if="count.answer_out_lost_count_today > count.answer_out_lost_count_yesterday" style="color: red">↑{{ count.answer_out_lost_count_today - count.answer_out_lost_count_yesterday }}</span>
              <span v-else-if="count.answer_out_lost_count_today < count.answer_out_lost_count_yesterday" style="color: green">↓{{ count.answer_out_lost_count_yesterday - count.answer_out_lost_count_today }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="count-sub-item">
            <div class="title">呼出未接听率</div>
            <div class="data">{{ count.answer_out_lost_percent_today | getPercent }}</div>
            <div class="">
              与昨日对比{{ count.answer_out_lost_percent_yesterday | getPercent }}
              <span v-if="count.answer_out_lost_percent_today > count.answer_out_lost_percent_yesterday" style="color: red">↑{{ (count.answer_out_lost_percent_today - count.answer_out_lost_percent_yesterday) | getPercent }}</span>
              <span v-else-if="count.answer_out_lost_percent_today < count.answer_out_lost_percent_yesterday" style="color: green">↓{{ (count.answer_out_lost_percent_yesterday - count.answer_out_lost_percent_today) | getPercent }}</span>
              <span v-else style="color: gray">持平</span>
            </div>
          </div>
        </el-col>
        <el-col :span="4"> </el-col>
        <el-col :span="4"> </el-col>
      </el-row>
    </div>
    <div ref="bottom" class="callDataCountContainerFlexColumn__bottom">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="逐小时总通话详情" name="total"></el-tab-pane>
        <el-tab-pane label="逐小时呼入通话详情" name="callIn"></el-tab-pane>
        <el-tab-pane label="逐小时呼出通话详情" name="callOut"></el-tab-pane>
        <el-tab-pane label="队列中用户放弃数" name="abandon"></el-tab-pane>
        <div id="dataChart" style="width: 100%"></div>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "CallDataCount",
  filters: {
    getPercent(val) {
      if (!val) return "0%";
      return Math.round(val * 100) / 100 + "%";
    },
  },
  data() {
    return {
      count: {},
      dataChart: null,
      activeName: "callIn",
    };
  },
  mounted() {
    this.getCount();
    this.handleClick();
    document.getElementById("dataChart").style.height = `${this.$refs.bottom.clientHeight - 100}px`;
  },
  methods: {
    getCount() {
      let url = `${this.baseUrls.callDataCount}/callDataCount`;
      let today = moment(new Date()).startOf("day").format("YYYY-MM-DD");
      let yesterday = moment(new Date()).startOf("day").add("day", -1).format("YYYY-MM-DD");
      let params = {
        today: today,
        yesterday: yesterday,
      };
      this.topsAjax.get(url, params).then((res) => {
        this.count = res;
      });
    },
    handleClick() {
      let url = `${this.baseUrls.callDataCount}/callDataCountByHour`;
      let params = {
        date: moment(new Date(), "YYYYMMDD").format("YYYYMMDD"),
      };
      this.topsAjax.get(url, params).then((res) => {
        if (this.dataChart) {
          this.dataChart.dispose();
        }
        if (this.activeName === "total") this.drawLine(res);
        if (this.activeName === "callIn") this.drawLine2(res);
        if (this.activeName === "callOut") this.drawLine3(res);
        if (this.activeName === "abandon") this.drawLine4(res);
      });
    },
    drawLine(data) {
      let day = [];
      let answerCount = [];
      let answerLostCount = [];
      let date = "";
      data.forEach((p) => {
        date = p.ndate;
        day.push(`${p.ntime}时`);
        answerCount.push(p.ncallInTotal);
        answerLostCount.push(p.ncallOutTotal);
      });
      date = moment(date, "YYYYMMDD").format("YYYY年MM月DD日");
      let optionline = {
        title: {
          text: `${date}逐小时总通话详情`,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["总接听数", "总未接听数"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: day,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "总接听数",
            type: "line",
            data: answerCount,
            color: "green",
          },
          {
            name: "总未接听数",
            type: "line",
            data: answerLostCount,
            color: "red",
          },
        ],
      };
      //基于准本好的DOM，初始化echarts实例
      this.dataChart = this.$echarts.init(document.getElementById("dataChart"));
      //绘制图表
      this.dataChart.setOption(optionline);
    },
    drawLine2(data) {
      let day = [];
      let answerCount = [];
      let answerLostCount = [];
      let answer15Count = [];
      let date = "";
      data.forEach((p) => {
        date = p.ndate;
        day.push(`${p.ntime}时`);
        answerCount.push(p.ncallInAnswer);
        answerLostCount.push(p.ncallInLost);
        answer15Count.push(p.ncallInAnswer15);
      });
      date = moment(date, "YYYYMMDD").format("YYYY年MM月DD日");
      let optionline = {
        title: {
          text: `${date}逐小时呼入通话详情`,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["接听数", "未接听数", "15s接听数"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: day,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "接听数",
            type: "line",
            data: answerCount,
            color: "green",
          },
          {
            name: "未接听数",
            type: "line",
            data: answerLostCount,
            color: "red",
          },
          {
            name: "15s接听数",
            type: "line",
            data: answer15Count,
            color: "orange",
          },
        ],
      };
      //基于准本好的DOM，初始化echarts实例
      this.dataChart = this.$echarts.init(document.getElementById("dataChart"));
      //绘制图表
      this.dataChart.setOption(optionline);
    },
    drawLine3(data) {
      let day = [];
      let answerCount = [];
      let answerLostCount = [];
      let answer15Count = [];
      let date = "";
      data.forEach((p) => {
        date = p.ndate;
        day.push(`${p.ntime}时`);
        answerCount.push(p.ncallOutAnswer);
        answerLostCount.push(p.ncallOutLost);
      });
      date = moment(date, "YYYYMMDD").format("YYYY年MM月DD日");
      let optionline = {
        title: {
          text: `${date}逐小时呼出通话详情`,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["接听数", "未接听数"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: day,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "接听数",
            type: "line",
            data: answerCount,
            color: "green",
          },
          {
            name: "未接听数",
            type: "line",
            data: answerLostCount,
            color: "red",
          },
        ],
      };
      //基于准本好的DOM，初始化echarts实例
      this.dataChart = this.$echarts.init(document.getElementById("dataChart"));
      //绘制图表
      this.dataChart.setOption(optionline);
    },
    drawLine4(data) {
      let day = [];
      let abandonCount = [];
      let date = "";
      data.forEach((p) => {
        date = p.ndate;
        day.push(`${p.ntime}时`);
        abandonCount.push(p.nabandon);
      });
      date = moment(date, "YYYYMMDD").format("YYYY年MM月DD日");
      let optionline = {
        title: {
          text: `${date}逐小时队列中用户放弃数`,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["用户放弃数"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: day,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "用户放弃数",
            type: "line",
            data: abandonCount,
            color: "red",
          },
        ],
      };
      //基于准本好的DOM，初始化echarts实例
      this.dataChart = this.$echarts.init(document.getElementById("dataChart"));
      //绘制图表
      this.dataChart.setOption(optionline);
    },
  },
};
</script>

<style lang="scss">
.callDataCountContainerFlexColumn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  overflow: hidden;
  .title {
    font-size: 18px;
  }
  .el-divider {
    margin: 12px 0;
  }
  &__top {
    //height: 300px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 10px;
    //flex: 1;
    margin-bottom: 10px;
  }
  &__bottom {
    //height: 300px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 10px;
    flex: 1;
  }
  .count-item {
    float: left;
    flex-direction: row;
    width: 100%;
    //flex: 1;
  }
  .el-row {
    padding-top: 20px;
  }
  .el-row:last-child {
    padding-bottom: 10px;
  }
  .count-sub-item {
    float: left;
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    color: #3f3f3f;
    width: 100%;

    .title {
      font-size: 14px;
    }
    .data {
      font-size: 28px;
      font-weight: bold;
    }
  }
  .el-tabs,
  .el-tabs__content {
    height: 100%;
  }
}
</style>
